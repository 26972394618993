import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  note: "",
  phone: "",
  address: "",
  schedule: "",
  latitude: "",
  longitude: "",
  selected_address: null
}
const terminal = createSlice({
  name: 'terminal',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_terminal(state) {
      Object.assign(state, initialState);
    },
    set_terminal(state, action) {
      state = {...state, ...action.payload}
      return state
    },
    set_note(state, action) {
      state.note = action.payload
      return state
    },
    set_phone(state, action) {
      state.phone = action.payload
      return state
    },
    set_address(state, action) {
      state.address = action.payload
      return state
    },
    set_schedule(state, action) {
      state.schedule = action.payload
      return state
    },
    set_latitude(state, action) {
      state.latitude = action.payload
      return state
    },
    set_longitude(state, action) {
      state.longitude = action.payload
      return state
    },
    set_selected_address(state, action) {
      state.selected_address = action.payload
      return state
    },
  },
});

export const {
  reset_terminal,
  set_note,
  set_phone,
  set_terminal,
  set_schedule,
  set_address,
  set_selected_address,
  set_latitude,
  set_longitude
} = terminal.actions;

export default terminal.reducer;
